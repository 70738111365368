import React, { useState, useEffect } from "react";
//css
import "./App.css";

//components
import HomePages from "./pages/HomePages";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Project from "./components/Project";
import About from "./components/About";
import Referance from "./components/Referance";
import Contact from "./components/Contact";
import Job from "./components/Job";
import InfoSecurity from "./components/InfoSecurity";

//data
import JsonData from "./data/data.json";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


function App() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePages />} />

          <Route
            path="/project"
            element={<Project data={landingPageData.Project} />}
          />

          <Route
            path="/about"
            element={<About data={landingPageData.About} />}
          />

          <Route
            path="/referance"
            element={<Referance data={landingPageData.Gallery} />}
          />
          <Route
            path="/contact"
            element={<Contact data={landingPageData.Contact} />}
          />
          <Route path="/job" element={<Job data={landingPageData.Job} />} />
        </Routes>
        <InfoSecurity/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
