import React from "react";

const Job = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center mt-10">
          <h2>SESASİS İş İlanları</h2>

          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.id}-${i}`} className="col-md-6 mx-auto team ">
                  <div className="thumbnail">
                    <div className="caption ">
                      <h1>{d.title}</h1>
                      <h5>Aranan Nitelikler</h5>
                      <ul>
                        <li>{d.features}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}

          <p>
            İlanda aranan niteliklerin size uygun olduğunu düşünüyorsanız.
            Özgeçmişlerinizi <b>info@sesasis.com</b> adresine gönderebilirsiniz.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Job;
