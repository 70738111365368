import Header from "../components/Header";
import Features from "../components/Features";
import About from "../components/About";
import Referance from "../components/Referance";

import React, { useEffect, useState } from "react";
import JsonData from "../data/data.json";

const HomePages = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Referance data={landingPageData.Gallery} />
    </div>
  );
};

export default HomePages;
