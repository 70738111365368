import React from "react";
import Image from "./Image";

const Referance = (props) => {
  return (
    <div id="portfolio" className="text-center referance-bg">
      <div className="container">
        <div className="section-title">
          <h2 style={{ color: "white" }}>Referanslarımız</h2>
        </div>
        <div className="row">
          <div className="portfolio-item">
            {props.data
              ? props.data.map((image, i) => (
                  <div
                    key={`${image.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4 m-3"
                  >
                    <Image
                      title={image.title}
                      largeImage={image.largeImage}
                      smallImage={image.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referance;

//
