import React from "react";

const InfoSecurity = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="about-text">
              <h2 style={{ textAlign: "center" }}>
                BİLGİ-KALİTE GÜVENLİĞİ- YÖNETİM SİSTEMİ POLİTİKASI BGYS.PLT.01
              </h2>
              <p>
                SESASIS BILGI TEKNOLOJILERI YAZILIM EGITIM DANISMANLIK ENERJI
                INSAAT TAAHHUT ITHALAT üst yönetimi olarak;
              </p>
              <p>
                SESASIS BILGI TEKNOLOJILERI YAZILIM EGITIM DANISMANLIK ENERJI
                INSAAT TAAHHUT ITHALAT Hizmetlerinde; ISO/IEC 27001:2013 Bilgi
                Güvenliği Yönetim Sistemi (BGYS), ISO/IEC 9001:2015 Kalite
                Yönetim Sistemi (KYS) standartlarından kurulan sistemlerin ana
                teması; insan, alt yapı, yazılım, donanım, müşteri bilgileri,
                kuruluş bilgileri, tüm iş ve işlemler, üçüncü şahıslara ait
                bilgiler ve finansal kaynaklar içerisinde bilgi güvenliği
                yönetiminin sağlandığını göstermek, kalite ve risk yönetimini
                güvence altına almak, bilgi-kalite güvenliği yönetimi süreç
                performansını ölçmek ve kalite-bilgi güvenliği ve müşteri
                memnuniyeti ile ilgili konularda üçüncü taraflarla olan
                ilişkilerin düzenlenmesini sağlamaktır.
              </p>
              <p>
                Bu doğrultuda BGYS, KYS Politikalarımızın amacı; BGYS
                kapsamında; <br />• İçeriden veya dışarıdan, bilerek ya da
                bilmeyerek meydana gelebilecek her türlü tehdide karşı SESASIS
                BILGI TEKNOLOJILERI YAZILIM EGITIM DANISMANLIK ENERJI INSAAT
                TAAHHUT ITHALAT bilgi varlıklarını korumak, bilgiye
                erişebilirliği iş prosesleriyle gerektiği şekilde sağlamak,
                yasal mevzuat gereksinimlerini karşılamak, sürekli iyileştirmeye
                yönelik çalışmalar yapmak, <br />
                • Yürütülen tüm faaliyetlerde Bilgi Güvenliği Yönetim Sisteminin
                üç temel öğesinin sürekliliğini sağlamak. Gizlilik: Önem taşıyan
                bilgilere yetkisiz erişimlerin önlenmesi, Bütünlük: Bilginin
                doğruluk ve bütünlüğünün sağlandığının gösterilmesi,
                Erişebilirlik: Yetkisi olanların gerektiği hallerde bilgiye
                ulaşılabilirliğinin gösterilmesi, <br />
                • Sadece elektronik ortamda tutulan verilerin değil; yazılı,
                basılı, sözlü ve benzeri ortamda bulunan tüm verilerin güvenliği
                ile ilgilenmek. <br />
                • Bilgi Güvenliği Yönetimi eğitimlerini tüm personele vererek
                bilinçlendirmeyi sağlamak. <br /> • Bilgi Güvenliğindeki
                gerçekte var olan veya şüphe uyandıran tüm açıklıkların, BGYS
                Ekibine rapor etmek ve BGYS Ekibi tarafından soruşturulmasını
                sağlamak. <br />
                • İş süreklilik planları hazırlamak, sürdürmek ve test etmek.{" "}
                <br />
                • Bilgi Güvenliği konusunda periyodik olarak değerlendirmeler
                yaparak mevcut riskleri tespit etmek. Değerlendirmeler
                sonucunda, aksiyon planlarını gözden geçirmek ve takibini
                yapmak. <br /> • Sözleşmelerden doğabilecek her türlü
                anlaşmazlık ve çıkar çatışmasını engellemek. <br /> • Bilgiye
                erişilebilirlik ve bilgi sistemleri için iş gereksinimlerini
                karşılamaktır.
              </p>

              <p>
                KYS ve kapsamında; <br></br>• Kalite Yönetim Sistemleri (KYS)
                kurmak ve işletmek Taahhüt ederiz.
              </p>
              <p>02.01.2023
                <br/>
              Genel Müdür
              <br/>
              Hasan Demircioğlu
              </p>
              
             
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSecurity;
